const useEmotive = () => {
  const trackPageView = () => {
    if (typeof window !== 'undefined' && window.edgetag) {
      window.edgetag('tag', 'PageView');
    }
  };
  const trackSubscribe = () => {
    if (typeof window !== 'undefined' && window.edgetag) {
      window.edgetag('tag', 'Subscribe');
    }
  };

  const trackCustomerData = (data: EmotiveData) => {
    if (typeof window !== 'undefined' && window.edgetag) {
      if (data.email) {
        window.edgetag('user', 'email', data.email);
      }
      window.edgetag('data', data);
    }
  };

  const trackPurchase = (data: EmotivePurchaseData) => {
    if (typeof window !== 'undefined' && window.edgetag) {
      window.edgetag('tag', 'Purchase', data);
    }
  };
  const trackViewContent = (data: EmotiveTrackContentData) => {
    if (typeof window !== 'undefined' && window.edgetag) {
      window.edgetag('tag', 'ViewContent', data);
    }
  };
  const trackAddToCart = (data: EmotiveTrackContentData) => {
    if (typeof window !== 'undefined' && window.edgetag) {
      window.edgetag('tag', 'AddToCart', data);
    }
  };

  const edgetag = {
    trackCustomerData,
    trackPurchase,
    trackPageView,
    trackSubscribe,
    trackViewContent,
    trackAddToCart,
  };
  return { edgetag };
};

export default useEmotive;

// https://emotive.gitbook.io/emotive-pixel#user-info
export type EmotiveData = {
  /**
   * lowercase and no leading or trailing spaces.
   */
  email?: string;
  /**
   * E.164
   */
  phone?: string;
  firstName?: string;
  lastName?: string;
  gender?: 'm' | 'f';
  /**
   * Format is YYYYMMDD, without punctuation.
   * Year: Use the YYYY format from 1900 to current year.
   * Month: Use the MM format: 01 to 12.
   * Date: Use the DD format: 01 to 31.
   */
  dateOfBirth?: string;
  /**
   * lowercase with no punctuation. If using special characters, the text must be encoded in UTF-8 format.
   */
  city?: string;
  /**
   * 2-character ANSI abbreviation code in lowercase. States outside the U.S. should be in lowercase with no punctuation, no special characters.
   */
  state?: string;
  /**
   * lowercase with no spaces and no dash
   */
  zip?: string;
  /**
   * lowercase, 2-letter country code in ISO 3166-1 Alpha 2
   */
  country?: string;
  /**
   * IPv4 / IPv6
   */
  ip?: string;
};

export type EmotiveContentProduct = {
  id?: string;
  quantity?: number;
  item_price?: number;
  title?: string;
  category?: string;
  image?: string;
  url?: string;
};

export type EmotivePurchaseData = {
  currency?: 'USD';
  value?: number;
  orderId?: string;
  eventId?: string;
  contents?: Array<EmotiveContentProduct>;
};

export type EmotiveTrackContentData = {
  currency?: 'USD';
  value?: number;
  contents?: Array<EmotiveContentProduct>;
};
