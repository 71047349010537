/** @jsxImportSource @emotion/react */
import { Button } from '@shared/react-ui-core';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';
import { useUpdateCheck } from './useUpdateCheck';

// Build command for Vercel:
// NODE_OPTIONS="--max-old-space-size=6144" && yarn run build:gatsby && generate-version -b public -v ../.vercel/output/static/version.json

// To test locally, first build the website with `yarn website build:version`.
// This build command is the only build script that generates `version.json`.
// Then, run `yarn website start` to start and open the website in your browser.
// Finally, commit a small change to the website and run `yarn website build:version`.
// Once the build is complete, you should see the update notification appear in the browser.

/**
 * UpdateAvailable component that displays a notification when an update is available.
 * It fetches `version.json` and compares it to window.__APP_VERSION__. `react-update-notification`
 * generates a new `version.json` when the app is built. The version is set to the latest commit hash.
 *
 * @param {boolean} [props.show] - A boolean prop that forces the component to show.
 *                                 This should only be used for testing purposes.
 */
const UpdateAvailable = ({ show }: { show?: boolean }): JSX.Element | null => {
  const { status, reloadPage } = useUpdateCheck({
    type: 'interval',
    interval: 15 * 1000,
  });

  useEffect(() => {
    // Overwrite `react-update-notification` version path to fix mismatch
    // version path. In order for Vercel to allow the `version.json` to be
    // served with the rest of the app, it must be placed in `.vercel/output/static`.
    // This causes `react-update-notification` set the `__APP_VERSION_FILE__` to
    // `.vercel/output/static/version.json` instead of `version.json`.
    window.__APP_VERSION_FILE__ = 'version.json';
  }, []);

  return (
    <AnimatePresence>
      {(status === 'available' || show) && (
        <motion.div
          className="fixed bottom-cove-15 left-cove-15 right-cove-15 z-50 flex flex-col gap-cove-5 rounded border border-amber-600 bg-amber-50 p-cove-15 shadow sm:left-[unset] sm:max-w-cove-320"
          initial={{ opacity: 0, y: 160, scale: 0.8, x: 50 }}
          animate={{ opacity: 1, y: 0, scale: 1, x: 0 }}
          exit={{ opacity: 0, y: 160, scale: 0.8, x: 50 }}
          transition={{
            type: 'spring',
            duration: 0.7,
            bounce: 0,
          }}
        >
          <p className="mb-0 text-xl font-semibold text-cove-navy-blue">
            Update Available
          </p>
          <p className="mb-cove-15">
            A new version of Cove is available. Please reload the page or click
            the button below to get the latest updates.
          </p>
          <Button
            variant="container"
            size="full"
            color="purple"
            onClick={reloadPage}
          >
            Reload
          </Button>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default UpdateAvailable;
