/** @jsxImportSource @emotion/react */
import { Debug } from '@shared/react-ui-core';
import { PostsProvider } from '@website/context/posts';
import type { PageProps } from 'gatsby';
import { type FC, type PropsWithChildren } from 'react';
import WeveUpgradedOutEquipmentModal from '../WeveUpgradedOutEquipmentModal';
import UpdateAvailable from '../update-available';

export type PageLayoutProps = PropsWithChildren<
  Omit<PageProps<unknown, unknown>, 'children'>
>;

const PostsProviderWrapper: FC<PageLayoutProps> = ({
  children,
  pageContext,
}) => {
  if (
    ['resources', 'resource-article', 'resource-category'].includes(
      pageContext?.layout,
    )
  ) {
    return <PostsProvider>{children}</PostsProvider>;
  }

  return <>{children}</>;
};

const PageLayout: React.FC<PageLayoutProps> = ({ children, ...props }) => {
  return (
    <PostsProviderWrapper {...props}>
      <>{children}</>
      <Debug />
      <UpdateAvailable />
      <WeveUpgradedOutEquipmentModal />
    </PostsProviderWrapper>
  );
};
export default PageLayout;
