/** @jsxImportSource @emotion/react */
import { ButtonUnstyled as Btn, ButtonUnstyledProps } from '@mui/core';
import { css, SerializedStyles, ClassNames } from '@emotion/react';
import Dots from '../Loaders/Dots';
import React from 'react';
import { openChat } from '@shared/chat-js';
import { cn } from '../../../lib';
import { cva } from 'class-variance-authority';

export interface Props
  extends Omit<ButtonUnstyledProps, 'color' | 'style' | 'size'> {
  cypressId?: string; // cypressId needs to be a constant unique value
  size?: 'large' | 'full';
  style?: 'container' | 'outlined' | 'text';
  variant?: 'container' | 'outlined' | 'text';
  chatButton?: boolean;
  color?: 'darkblue' | 'purple';
  target?: string;
  loading?: boolean;
  disabled?: boolean;
  isUnderlined?: boolean;
  submitted?: boolean;
  css?: SerializedStyles | SerializedStyles[];
}

const containerOutlineBase =
  'flex items-center justify-center min-h-9 box-border shadow text-base leading-4 px-5 py-cove-5 min-w-cove-87 focus:outline-none';

const buttonVariants = cva(
  'text-base bg-inherit flex transition-all relative',
  {
    variants: {
      variant: {
        container: cn(
          containerOutlineBase,
          'bg-cove-navy-blue font-semibold rounded-full text-white border-2 border-transparent [&:not([disabled])]:hover:text-white [&:not([disabled])]:hover:bg-cove-navy-blue [&:not([disabled])]:hover:border-cove-navy-blue [&:not([disabled])]:hover:shadow disabled:text-white disabled:bg-initial disabled:shadow disabled:border-transparent'
        ),
        outlined: cn(
          containerOutlineBase,
          'bg-white rounded-full font-medium text-cove-blue border-2 border-cove-blue [&:not([disabled])]:hover:text-white [&:not([disabled])]:hover:bg-cove-navy-blue [&:not([disabled])]:hover:border-cove-navy-blue [&:not([disabled])]:hover:shadow disabled:text-cove-dark-grey disabled:bg-white disabled:shadow disabled:border-cove-dark-grey'
        ),
        text: 'rounded-none py-0 px-auto hover:bg-initial [&:not([disabled])]:hover:bg-initial [&:not([disabled])]:hover:shadow-none',
      },
      size: {
        small: '',
        large:
          'w-full max-w-full flex items-center justify-center xs:max-w-button min-h-[50px] min-w-[220px] rounded-full',
        full: 'min-w-[220px] min-h-[50px] w-full max-w-full',
      },
      color: {
        darkblue: 'text-cove-navy-blue',
        purple: 'text-cove-blue',
      },
    },
    compoundVariants: [
      {
        color: 'purple',
        variant: 'container',
        size: ['full', 'large', 'small'],
        className:
          'text-white border-cove-blue bg-cove-blue [&:not([disabled])]:hover:text-white [&:not([disabled])]:hover:bg-cove-navy-blue [&:not([disabled])]:hover:border-cove-navy-blue [&:not([disabled])]:hover:shadow',
      },
      {
        color: 'darkblue',
        variant: 'container',
        size: ['full', 'large', 'small'],
        className: 'text-white border-cove-navy-blue',
      },
      {
        color: 'purple',
        variant: 'outlined',
        size: ['full', 'large', 'small'],
        className:
          "text-cove-blue border-cove-blue [&:not([disabled])]:hover:text-white [&:not([disabled])]:hover:bg-cove-blue [&:not([disabled])]:hover:border-cove-blue [&:not([disabled])]:hover:shadow data-[loading='true']:border-cove-blue data-[loading='true']:shadow data-[loading='true']:hover:text-white data-[loading='true']:hover:bg-cove-blue data-[loading='true']:hover:border-cove-blue data-[loading='true']:hover:shadow",
      },
      {
        color: 'darkblue',
        variant: 'outlined',
        size: ['full', 'large', 'small'],
        className: 'text-cove-navy-blue border-cove-navy-blue',
      },
      {
        color: ['darkblue', 'purple'],
        size: ['small', 'full'],
        variant: 'text',
        className: 'min-w-0 min-h-0 w-content flex items-center',
      },
    ],
    defaultVariants: {
      size: 'small',
      color: 'purple',
      variant: 'container',
    },
  }
);

const Button: React.FC<Props> = ({
  loading,
  disabled,
  size,
  style,
  variant = 'container',
  chatButton,
  color = 'darkblue',
  css: propsCss = null,
  isUnderlined = false,
  type = 'button',
  submitted = false,
  cypressId = '',
  children,
  className,
  ...props
}) => {
  const isDarkBlue = color === 'darkblue';

  const deprecatedStyle = typeof style === 'string';
  const finalStyle = deprecatedStyle ? style : variant;
  const isContainer = finalStyle === 'container';

  return (
    <ClassNames>
      {({ css, cx }) => (
        <Btn
          component={props.href || props.to ? 'a' : 'button'}
          cy-data={cypressId}
          type={type}
          data-loading={loading}
          disabled={loading || disabled}
          onClick={chatButton ? () => openChat() : props.onClick}
          className={cx(
            cn(
              buttonVariants({ variant: finalStyle, color, size }),
              isUnderlined && 'underline',
              props.href && 'px-[8px] py-[6px]',
              className
            ),
            css`
              ${propsCss}
            `
          )}
          style={{
            '--dot-color': isContainer
              ? '#ffffff'
              : isDarkBlue
                ? '#002334'
                : '#3722d3',
          }}
          {...(props.to ? { href: props.to } : {})}
          {...props}
        >
          {loading ? (
            <>
              <Dots dotColor={'var(--dot-color)'} />
              <p className="invisible absolute text-transparent">{children}</p>
            </>
          ) : (
            children
          )}
        </Btn>
      )}
    </ClassNames>
  );
};

export default Button;
