declare global {
  interface Window {
    tidioChatApi: {
      open: () => void;
    };
  }
}

function onTidioChatApiReady() {
  window.tidioChatApi.open();
}

async function loadTidioChatWidget() {
  const tidioScript = window.document.createElement('script');
  tidioScript.id = 'tidioChatWidget';
  tidioScript.src = 'https://code.tidio.co/d9thk8ahb9shoozzewtnbjcgecm3mil7.js';
  window.document.head.appendChild(tidioScript);
  return true;
}

export async function openChat() {
  if (!window?.tidioChatApi) {
    window.addEventListener('tidioChat-ready', onTidioChatApiReady);
    await loadTidioChatWidget();
    return;
  }
  window.tidioChatApi.open();
}
