import { useCallback, useEffect, useState } from 'react';
import {
  UpdateHookParams,
  UpdateHookReturnValue,
  UpdateStatus,
  VersionFileResponse,
} from './types';

export const useUpdateCheck = ({
  interval,
  type,
  ignoreServerCache,
}: UpdateHookParams): UpdateHookReturnValue => {
  const reloadPage = () =>
    typeof window !== 'undefined' && window.location.reload();
  const [currentVersion, setCurrentVersion] = useState<string | undefined>(
    undefined,
  );
  const [status, setStatus] = useState<UpdateStatus>(UpdateStatus.checking);

  const fetchVersionFile = useCallback(async () => {
    let requestStr = `/version.json`;

    if (ignoreServerCache) {
      requestStr += `?v=${Date.now()}`;
    }

    return fetch(requestStr)
      .then((res) => res.json() as Promise<VersionFileResponse>)
      .then((data) => {
        return data;
      })
      .catch(() => {
        return { version: undefined };
      });
  }, [ignoreServerCache]);

  const checkUpdate = useCallback(() => {
    if (typeof currentVersion === 'undefined') {
      setStatus(UpdateStatus.current);
      return;
    }

    setStatus(UpdateStatus.checking);

    fetchVersionFile()
      .then((data) => {
        if (!!data.version) {
          if (data.version === currentVersion) {
            setStatus(UpdateStatus.current);
          } else {
            setStatus(UpdateStatus.available);
          }
        }
      })
      .catch(() => {
        setStatus(UpdateStatus.current);
      });
  }, [currentVersion, fetchVersionFile]);

  useEffect(() => {
    if (type !== 'manual') {
      checkUpdate();
    }
  }, [checkUpdate, type]);

  useEffect(() => {
    if (status !== UpdateStatus.current) {
      return;
    }

    if (type === 'interval') {
      const timeoutId = window.setTimeout(
        () => checkUpdate(),
        interval || 10000,
      );

      return () => {
        window.clearTimeout(timeoutId);
      };
    }
  }, [type, interval, status, checkUpdate]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('__APP_VERSION__', window.__APP_VERSION__);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const version =
        window.__APP_VERSION__ ??
        window.localStorage.getItem('__APP_VERSION__') ??
        undefined;

      console.log({ version });

      // If user doesn't have a version, assume they're on the latest version
      if (version === 'undefined') {
        fetchVersionFile().then((data) => {
          if (data.version) {
            setCurrentVersion(data.version);
            window.localStorage.setItem('__APP_VERSION__', data.version);
            window.__APP_VERSION__ = data.version;
          } else {
            setCurrentVersion('latest');
            window.localStorage.setItem('__APP_VERSION__', 'latest');
            window.__APP_VERSION__ = 'latest';
          }
        });
      } else {
        setCurrentVersion(version);
      }
    }
  }, [fetchVersionFile]);

  return { status, reloadPage, checkUpdate };
};
